// import { DirectiveOptions } from 'vue'
// // 在vue上挂载一个指量 preventReClick
// const preventReClick: DirectiveOptions = {
//     mounted: function (el:any, binding:any) {
//         el.addEventListener('click', () => {
//             if (!el.disabled) {
// 				console.log(el.disabled,'OK');
//                 el.disabled = true
//                 setTimeout(() => {
//                     el.disabled = false
//                 }, binding.value || 3000)
//                 //binding.value可以自行设置。如果设置了则跟着设置的时间走
//                 //例如：v-preventReClick='500'
//             }
//         })
//     }
// };
// export default preventReClick;

import { App } from 'vue'

export default (app: App<Element>) => {
    //自定义组件
    app.directive('preventReClick', (el: any, binding:any) => {
        el.addEventListener('click', () => {
                        if (!el.disabled) {
            				console.log(el.disabled,'OK');
                            el.disabled = true
                            setTimeout(() => {
                                el.disabled = false
                            }, binding.value || 3000)
                            //binding.value可以自行设置。如果设置了则跟着设置的时间走
                            //例如：v-preventReClick='500'
                        }
        })
    });
}