<template>
  <router-view/>
</template>

<style lang="less">

</style>
<script lang="ts">
	
</script>
