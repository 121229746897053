import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'
import './assets/css/common.css'
import directives from '@/util/v-repeat'
// import request from './service/request'
// console.log(request)
// debugger;
// request.getReq({
// 	    method:'get',
// 	    url:'/questions/list',
// 	    params:{
// 			device:2,
// 			_code:'0710aB0w3yoK0Y2wEW2w3ggqd320aB0x',
// 			code:'123456',
// 			phone:'18820057791'
// 		},
// 	   }).then((res:any)=>{
// 		   console.log(res);
// 			debugger;
// 		}).catch((err:any)=>{
// 			console.log(err);
// 			debugger;
// 		})


createApp(App).use(store).use(router).use(directives).mount('#app');
// const app = createApp({})
// app.directive('preventReClick',{
// 	    mounted: function (el:any, binding:any) {
// 	        el.addEventListener('click', () => {
// 	            if (!el.disabled) {
// 					console.log(el.disabled,'OK');
// 	                el.disabled = true
// 	                setTimeout(() => {
// 	                    el.disabled = false
// 	                }, binding.value || 3000)
// 	                //binding.value可以自行设置。如果设置了则跟着设置的时间走
// 	                //例如：v-preventReClick='500'
// 	            }
// 	        })
// 	    }
// })

