import { createRouter, createWebHashHistory, RouteRecordRaw,createWebHistory} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/index/index.vue'),
	meta: {
		title: "葫芦娃首页",
		content: '葫芦娃首页'
	}
  },
  {
    path: '/phoneLogin',
    name: 'phoneLogin',
	component: () => import('@/views/login/phoneLogin.vue'),
  },
  {
	path:'/pswLogin',
	name: 'pswLogin',
	component: () => import('@/views/login/pswLogin.vue'),
  },
  {
  	path:'/forgetpsw',
  	name: 'forgetpsw',
  	component: () => import('@/views/forgetpsw/forgetpsw.vue'),
  },
  {
  	path:'/register',
  	name: 'register',
  	component: () => import('@/views/register/register.vue'),
  },
  {
  	path:'/luntang',
  	name: 'luntang',
  	component: () => import('@/views/luntang/luntang.vue'),
  },
  {
  	path:'/gongnue',
  	name: 'gongnue',
  	component: () => import('@/views/gongnue/gongnue.vue'),
  },
  {
  	path:'/my',
  	name: 'my',
  	component: () => import('@/views/my/my.vue'),
  },
  {
  	path:'/myEdit',
  	name: 'myEdit',
  	component: () => import('@/views/my/myEdit.vue'),
  },
  {
  	path:'/content',
  	name: 'content',
  	component: () => import('@/views/content/detail.vue'),
  },
  {
  	path:'/search',
  	name: 'search',
  	component: () => import('@/views/search/search.vue'),
  },
  {
  	path:'/publish',
  	name: 'publish',
  	component: () => import('@/views/publish/publish.vue'),
  },
  {
  	path:'/message',
  	name: 'message',
  	component: () => import('@/views/message/message.vue'),
  },
  {
  	path:'/notice',
  	name: 'notice',
  	component: () => import('@/views/notice/notice.vue'),
  }
]

const router = createRouter({
  history:createWebHistory(),
  //history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {    
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
	if(window.pageYOffset){
		console.log(window.pageYOffset);
		 (window as any).pageYOffset = 0
	}
	
	const token:any=localStorage.getItem('token');
	let needToken=false;
	if(to.name=="publish"||to.name=='my'||to.name=='myEdit'){
		needToken=true;
	}
	if(!token&&needToken){
		 next({
		    path: '/phoneLogin'
		 })
	}else{
		 next()
	}  
   
})
export default router
