

export default {
	SET_TESTTOKEN(state:any, testToken:string){
		debugger;
		state.testToken = testToken || '';
	},
	SET_TOKEN(state:any,token:string){
		state.token=token||'';
	}
}