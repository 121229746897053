

export default {
	setTestToken({ commit }:any,testToken:any){
		debugger;
		commit('SET_TESTTOKEN', testToken);
	},
	setToken({commit}:any,token:any){
		commit("SET_TOKEN",token);
	}
}